.quote-section-mobile {
  display: none;
}

.quote-section {
  display: flex;
  border-top: var(--border);
  border-bottom: var(--border);
  position: relative;
  justify-content: space-between;
  margin-bottom: 100px;
}

.video-quote {
  width: 80%;
  display: flex;
  height: 100%;
}

.logo-quote {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 60px;
  padding-bottom: 40px;
  width: 50%;
  margin-right: 10px;
}

.logo-quote img {
  width: 40px;
}

.logo-quote p {
  font-size: 2rem;
  font-weight: 500;
  max-height: 100%;
}

#quote-video {
  width: 100%;
  height: 100%;
  margin: 0;
}

.quote-avatar {
  align-self: start;
  border-bottom: var(--border);
  border-left: var(--border);
  border-right: var(--border);
  position: absolute;
  top: 0;
  width: 250px;
  display: flex;
  right: 0;
}

.quote-avatar img {
  width: 60px;
  align-self: end;
}

.quote-avatar p:nth-child(1) {
  margin-bottom: 5px;
}

.quote-avatar p:nth-child(2) {
  margin-top: 0px;
  margin-bottom: 10px;
}

.quote-video-wrapper {
  display: flex;
  align-items: flex-end;
  width: 50%;
  max-width: 450px;
}

@media (max-width: 800px) {
  .quote-section {
    display: none;
  }

  .quote-section-mobile {
    display: flex;
    padding: 0;
    align-items: flex-end;
    /* width: calc(100vw - 22px); */
    height: 70vw;
    z-index: 1000;
    border-right: var(--border);
  }

  .quote-avatar-mobile {
    flex-direction: column;
    border-bottom: var(--border);
    border-left: var(--border);
    border-right: var(--border);
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .avatar-mobile img {
    width: 20%;
  }

  .avatar-mobile {
    font-size: 0.7rem;
    padding-top: 10px;
  }

  .avatar-mobile {
    border-top: var(--border);
    display: flex;
  }

  .logo-quote {
    padding: 10px;
    width: 95%;
    height: 60%;
    margin-right: 0;
  }
  .logo-quote img {
    width: 15%;
  }

  .logo-quote p {
    font-size: 0.8rem;
    padding: 0;
  }

  #quote-video {
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .quote-video-wrapper-mobile {
    height: 100%;
    width: 50%;
  }
}


.easter {
	text-decoration: none;
}