.footer-sections {
  position: absolute;
  left: 50%;
  bottom: 100px;
  width: 70%;
  height: 35%;
  transform: translate(-50%, 0);
}

.footer-container {
  position: relative;
  padding-left: 261px;
  padding-right: 261px;
}

.footer-sections .footer-logo {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, 0);
}

.footer-video {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  width: 100%;
  height: 360px;
  outline: none;
  margin-bottom: 0;
  mix-blend-mode: hard-light;
}

.footer-border {
  border: var(--border);
  padding: 50px;
  width: 50%;
  height: 50%;
  box-sizing: border-box;
  float: left;
  text-align: center;
  padding: 16px;
}

.footer-border:hover {
  background-color: rgba(63, 63, 63, 0.5);
}

@media (max-width: 1440px) {
  .footer-container {
    position: relative;
    padding: 0;
  }

  .footer-hash {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .footer-sections {
    font-size: 0.7rem;
    width: 90%;
    overflow: hidden;
  }
}
