.difference-container {
  position: relative;
  width: 100%;
  background: url('/public/images/newspaper.png') right top no-repeat;
  background-size: contain;
  display: flex;
  margin-bottom: 100px;
}

.row {
  display: flex;
}

.in-view {
  animation-play-state: running !important;
}

.border {
  border: var(--border);
  width: calc(100vw / 6 + 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vw / 6 + 5px);
  padding: 0;
  animation: backgroundTransition 10s forwards paused;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
}

.border--text {
  padding: 0 10px 0 10px;
}

@keyframes backgroundTransition {
  0% {
    background: black;
  }
  100% {
    background: transparent;
  }
}

.difference-video {
  margin-top: calc(100vw / 6);
  width: calc(50vw - 6px);
  height: calc(calc(100vw / 6) * 4 + 25px);
}

@media (max-width: 800px) {
  .border {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .border {
    font-size: 6px;
  }
}

.row:first-child .border {
  border-top: none;
}

.row:last-child .border {
  border-bottom: none;
}

.border:first-child {
  border-left: none;
}

.border:last-child {
  border-right: none;
}

.difference-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newspaper-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
}

.newspaper-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #000;
}

.yellow {
  background-color: #ffeb3b;
  color: #000;
  animation: backgroundTransitionYellow 10s forwards paused;
}

@keyframes backgroundTransitionYellow {
  0% {
    background: black;
  }
  100% {
    background: #ffeb3b;
  }
}


.overlay-text.in-view {
  opacity: 0; 
  z-index: -1;
} 


.overlay-text {
  position: absolute;
  color: #fff;
  font-size: 12vw;
  line-height: 34%;
  top: calc(100vw / 6);
  left: 2%;
  animation: overlayText 7s forwards paused;
}

@keyframes overlayText {
    0% {
      opacity: 1;
      z-index: 10; 
    }
    99% {
      opacity: 0;
      z-index: 10; 
    }
    100% {
      opacity: 0;
      z-index: -1; 
      display: none;
    }
  }

.black {
  animation: backgroundTransitionBlack 10s forwards paused;
}

@keyframes backgroundTransitionBlack {
  0% {
    background: black;
    color: #000;
  }
  100% {
    background: black;
    color: #fff;
  }
}

.logo {
  background-color: #000;
  background-image: url('/public/images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.japanese-writing {
  font-size: 2rem;
}

.why-container {
  padding: 40px;
}

@media (max-width: 800px) {
  .difference-container {
    margin-bottom: 0;
  }
  .japanese-writing {
    font-size: 1.2rem;
  }
  .why {
    font-size: 1rem;
  }
  .why-container {
    padding: 20px;
  }
}
