.card {
  border: var(--border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
}
.card .content {
  flex: 0 1 auto;
}

.card-header {
  border-bottom: var(--border);
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 20px 0 20px 0;
}

.card-footer {
  display: flex;
  flex-direction: row-reverse;
}

.outline-button {
  border: var(--border);
  padding: 10px;
  margin: 0px 10px 10px 0;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  color: white;
  width: 120px;
  cursor: pointer;
}

@media (max-width: 800px) {
  .card {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75vw;
  }
  .outline-button {
    margin: 0px 10px 10px 0;
  }

  .card-footer {
    flex-grow: 0;
  }
  .card-footer {
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .card {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75vw;
  }

  .outline-button {
    margin: 0px 10px 10px 0;
  }

  .card-footer {
    flex-grow: 0;
  }
  .card-footer {
    flex-direction: row-reverse;
    align-items: center;
  }
  .image-container {
    min-width: 93px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .card {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60vw;
  }
}
