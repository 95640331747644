.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 1s ease-out;
}

.splash-screen.fade-out {
  opacity: 0;
}

.logo {
  width: 150px;
  height: auto;
  animation: pulse 2s infinite;
}
.splash-screen > div {
	width: 33vw;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
