header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container img {
    padding-right: 1rem;

}


.logo-container p {
    font-weight: 800;
    font-size: 1.2rem;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li {
    float: left;
}

li a {
    display: block;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    min-width: 100%;
}

@media (max-width:800px) {
    nav ul {
      display: none;
    }
  }

.nav-text li {
	margin-left: 6px;
	margin-bottom: 3px;
	font-style: italic;
}