.card-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1rem;
  margin-bottom: 100px;
  -webkit-overflow-scrolling: touch;
}

.description {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.description .image-container div {
  width: 110px;
  height: 110px;
}

@media (max-width: 800px) {
  .description .image-container div {
    width: 58px;
    height: 58px;
  }
}
.description .image-container {
  margin-left: 10px;
}

.description p {
  font-size: 0.9em;
  padding: 3rem;
}

@media (max-width: 800px) {
  .description p {
    font-size: 0.9em;
    padding: 1rem;
  }
  .card-container {
    margin-left: 10vw;
    margin-bottom: 30px;
  }
}
