/* HeaderNav.css */

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* padding: 20px 90px; */
  animation: nav-fadeIn 0.3s ease-in-out;
}

.nav-modal {
  background: var(--line, #3F3F3F);
  padding: 80px; /* Added padding */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
  max-width: 70%;
  width: 100%;
  max-height: calc(90vh - 160px);
  overflow-y: auto;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  position: relative;
  animation: nav-slideIn 0.3s ease-in-out;
}

.nav-closeButton {
  position: absolute;
  top: 50px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  font-family: 'Kode Mono';
}

.nav-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.nav-subtitle {
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 0;
}

.nav-text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  padding-top: 0px;
  margin-top: 0px;
}

/* Keyframe animations */
@keyframes nav-fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes nav-slideIn {
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Hide modal on screens smaller than 800px */
@media (max-width: 799px) {
  .nav-modal {
    display: none;
  }
}

.modal-logo {
  margin-top: 26px;
}

.mobile-menu-icon {
  display: none;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
}

.mobile-menu-icon img {
  width: 50px;
  height: 50px;
}

@media (max-width: 800px) {
  .mobile-menu-icon {
    display: block;
  }

  .nav-modal {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    padding: 20px;
  }

  .nav-title, .nav-subtitle {
    font-size: 18px;
  }

  .nav-text {
    font-size: 14px;
  }
  .nav-text:last-of-type {
    margin-bottom: 40px;
  }

  .initiatives {
    display:block;
    padding: 0;
  }
  .initiatives li {
    margin-bottom: 6px;
    font-size: 14px;
    font-style: italic;
  }
}
