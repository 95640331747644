.hero-container {
    position: relative;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    outline: none;
    margin-bottom: 100px;
}

video :focus {
    outline: none;
}

.tower {
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translate(-50%, 0);
}

.japanese-writings {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0);

}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
    .tower {
        width: 100%;
        max-width: 800px;
    }

    .japanese-writings {
        width: 100%;

        top:50%;
        max-width: 800px;
    }
}

@media (max-width:767px) {
    video {
        height: 50vh;
    }

    .tower {
        width: 90%;
        max-width: 400px;
    }
    
    .japanese-writings {
        width: 100%;
        max-width: 400px;

        top:50%;
    }

}